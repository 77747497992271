/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "us-west-2",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: "us-west-2_3evpQGyi5",
    aws_user_pools_web_client_id: "115fkn7c7089gveb3f2n0rop4a",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_content_delivery_bucket_region: "us-west-2"
};

export default awsmobile;
