<script setup lang="ts">
</script>

<template>
  <div id="app-loader" style="margin-top: auto">
    <figure class="image" style="width: 300px">
      <img src="@/assets/images/logo.png" />
    </figure>

    <h2 class="mt-3 text-center" style="font-weight: 630; font-size: 20px">
      Loading app, please wait...
    </h2>
    <!-- < u :loading="true" :use-logo="true"></> -->
  </div>
</template>
